import { API } from "aws-amplify";
export default (settings) => {
  const query = `mutation editSettings($settings: SettingsInput!){
      editSettings(settings: $settings) {
        userProfileRequired
        userNotificationsCriteriaRequired
        depositPaymentProvider
        depositIframeProvider
        lenderDirect90daysEnabled
      }
    }`;
  return API.graphql({
    query,
    variables: { settings },
  });
};
