<template>
  <nav>
    <ul class="listNavigation">
      <li>
        <router-link
          :to="'/buy-commercial-real-estate-loans'"
          class="header-link custom-header-link header-link--first"
        >
          Buy
        </router-link>
      </li>
      <li v-if="$exclusiveFeature">
        <v-menu v-model="sellMenuModel" offset-y transition="false">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="header-link custom-header-link sell-link"
              v-bind="attrs"
              v-on="on"
            >
              Sell
              <v-img
                :src="menuIcon"
                width="8"
                height="8"
                class="sell-link-img"
                :class="{ 'sell-link-img-rotate': !sellMenuModel }"
              ></v-img>
            </span>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title class="drop-down-item pa-0 pb-1">
                <router-link
                  tag="a"
                  to="/loan-sales"
                  class="header-link custom-header-link"
                >
                  Self-Service
                </router-link>
              </v-list-item-title>
              <v-list-item-title class="drop-down-item pa-0 pt-1">
                <router-link
                  tag="a"
                  to="/loan-sale-advisory"
                  class="header-link custom-header-link"
                >
                  Full-Service
                </router-link>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </li>
      <li v-else>
        <router-link
          tag="a"
          to="/loan-sales"
          class="header-link custom-header-link header-link--first"
        >
          Sell
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/faq" class="header-link custom-header-link">
          FAQ
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import menuIcon from "@/assets/img/menu-up-icon-white.svg";

export default {
  name: "Navbar",
  data() {
    return {
      routes: [
        {
          name: "Buy",
          to: "/buy-commercial-real-estate-loans",
        },
        {
          name: "Sell",
          to: "/choosePlan",
        },
      ],
      sellMenuModel: false,
      menuIcon,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/components/navbarItems";

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .listNavigation {
    justify-content: end;
    column-gap: 40px;
  }
  ::v-deep .v-list-item:hover .drop-down-item a {
    color: inherit !important;
  }
  .drop-down-item {
    .router-link-active {
      &.header-link {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: -2px;
          height: 2px !important;
          background-color: #dd226f !important;
          border-radius: 5px !important;
        }
      }
    }
  }
  .custom-header-link {
    position: relative;
    &::after {
      height: 2px !important;
      background-color: #dd226f !important;
      border-radius: 5px !important;
    }
  }
  .header-link {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
  }
  .v-list {
    padding: 0;
    border-radius: 5px !important;
  }
  .v-list-item {
    display: flex;
    flex-direction: column;
    min-height: auto;
    padding: 10px 18px 10px 10px;
  }
  .v-list-item {
    &:hover {
      background: none;
    }
  }
  .v-list-item__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px !important;
    color: #001154;
    cursor: pointer;
    overflow: initial;
  }
  .sell-link {
    display: flex;
    align-items: center;

    .sell-link-img {
      margin-top: -4px;
      margin-left: 6px;
    }
    .sell-link-img-rotate {
      transform: rotate(180deg);
    }
  }
}

@media #{map-get($display-breakpoints, 'xl-only')} {
  .listNavigation {
    column-gap: 56px;
  }
  .header-link {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
  }
}
</style>
