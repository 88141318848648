import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Zentinel from "./plugins/zentinelNew";
import router from "./router";
import Amplify from "aws-amplify";
import store from "./store";
import VueMoment from "vue-moment";
import config from "./aws_config";
import ComponentSpinner from "@/components/ui/ComponentSpinner";
import fromCamelCase from "@/utils/fromCamelCase";
import MoneyFormatPrimary from "@/filters/MoneyFormatPrimary";
import FormatPhoneNumber from "@/filters/formatPhoneNumber";
import VueCalendly from "vue-calendly";
import ErrorMessages from "@/components/ui/ErrorMessages.vue";
import vueSmoothScroll from "vue2-smooth-scroll";
import Cleave from "vue-cleave-component";
import VueGtm from "vue-gtm";
import gtmConfig from "@/services/gtmConfig";
import VueScrollTo from "vue-scrollto";
import VueAwesomeCountdown from "vue-awesome-countdown";
import GlobalLoader from "@/components/ui/GlobalLoader";
import VueScrollactive from "vue-scrollactive";
import formatPercent from "@/filters/formatPercent";
import formatCurrency from "@/filters/formatCurrency";
import formatBoolean from "@/filters/formatBoolean";
import formatAssetStatus from "@/filters/formatAssetStatus";
import formatNumberToFixed from "@/filters/formatNumberToFixed";
import parseCurrency from "@/utils/parseCurrency";
import formatMoney from "@/utils/formatMoney";
import { StripePlugin } from "@vue-stripe/vue-stripe";
import app_config from "@/app_config";
import SecondSelect from "@/components/ui/SecondSelect.vue";
import appSecondTextField from "@/components/ui/appSecondTextField";
import selectDatePopUp from "@/components/ui/selectDatePopUp";
import appNumberInput from "@/components/ui/AppNumberInput.vue";
import { fieldNames } from "@/assets/data/enums/fieldNames.js";
import appDecimalInput from "@/components/Common/AppDecimalInput.vue";
import AppDigitInput from "@/components/Common/AppDigitInput.vue";
import Hotjar from "vue-hotjar";
import number from "@/utils/numberFormat";

import VueSignaturePad from "vue-signature-pad";
Vue.use(VueSignaturePad);

import VueJsonLD from "vue-jsonld";
Vue.use(VueJsonLD);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

Vue.use(number, { precision: 4 });
Vue.component("GlobalLoader", GlobalLoader);
Vue.component("app-digit-input", AppDigitInput);
Vue.component("second-select", SecondSelect);
Vue.component("app-second-text-field", appSecondTextField);
Vue.component("select-date-pop-up", selectDatePopUp);
Vue.component("app-number-input", appNumberInput);
Vue.component("app-decimal-input", appDecimalInput);

const options = {
  pk: app_config.STRIPE_PUBLISHABLE_KEY,
};
Vue.use(VueScrollTo);
Vue.use(VueScrollactive);
Vue.use(StripePlugin, options);

Vue.use(VueAwesomeCountdown, "vac");
Vue.component("ErrorMessages", ErrorMessages);
// eslint-disable-next-line no-console
console.log("STORE", store);

Vue.use(VueGtm, {
  id: gtmConfig.GTM_ACCOUNT,
  // queryParams: {
  //   gtm_auth: gtmConfig.GTM_AUTH,
  //   gtm_preview: gtmConfig.GTM_PREVIEW,
  //   gtm_cookies_win: "x",
  // },
  defer: false,
  // enabled: ["prod", "stage"].includes(store.getters.environment),
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: true,
});
const userId = store.getters.user?.id;
window.dataLayer?.push({
  user_id: userId ? userId : null,
  event: "userIdSet",
});

Vue.use(Hotjar, {
  id: ["prod"].includes(store.getters.environment) ? "2824246" : "3516406", // Hotjar Site ID,
  // isProduction: ["prod"].includes(store.getters.environment),
});

Vue.use(Zentinel);

Vue.use(Cleave);
Vue.use(vueSmoothScroll);

Vue.use(VueCalendly);

Amplify.configure(config);
Vue.use(VueMoment);

Vue.filter("fromCamelCase", fromCamelCase);
Vue.filter("moneyFormatPrimary", MoneyFormatPrimary);
Vue.filter("formatBoolean", formatBoolean);
Vue.filter("formatPhoneNumber", FormatPhoneNumber);
Vue.filter("formatPercent", formatPercent);
Vue.filter("currency", formatCurrency);
Vue.filter("assetStatus", formatAssetStatus);
Vue.filter("formatNumberToFixed", formatNumberToFixed);
Vue.prototype.$parseCurrency = parseCurrency;
Vue.prototype.$formatMoney = formatMoney;
Vue.prototype.$fieldNames = fieldNames;
Vue.prototype.$eventHub = new Vue();
Vue.prototype.$exclusiveFeature = false;

Vue.component("ComponentSpinner", ComponentSpinner);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
